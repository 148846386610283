import React from 'react'
import theme from '../../../theme'
// Types
import { TestimonialProps } from '../../../types/items'
import { Box, Button, Flex, Image, Text } from '../../../atoms'

type TestimonialSmallProps = TestimonialProps & { backgroundColor?: string | keyof typeof theme.colors }

const TestimonialSmall: React.FC<TestimonialSmallProps> = ({
  author,
  profilePicture,
  quote,
  role,
  button,
  backgroundColor,
}) => {
  return (
    <Box bg={backgroundColor || 'lightBlueShade'} py={[6, 7, 8]} px={[6, 7, 8]} borderRadius={'medium'}>
      <Flex alignItems={'center'}>
        <Box maxWidth={80}>
          <Image
            draggable={false}
            alt={profilePicture.alt || author}
            image={profilePicture.gatsbyImageData}
            style={{ overflow: 'hidden', borderRadius: '9999px' }}
            imgStyle={{ borderRadius: '9999px' }}
          />
        </Box>
        <Box ml={5}>
          <Text fontWeight={'semi'} lineHeight={'28px'}>
            {author}
          </Text>
          <Text opacity={0.63}>{role}</Text>
        </Box>
      </Flex>
      <Box mt={6}>
        <Box>
          <Text fontSize={[3, 4]} lineHeight={1.5}>
            {quote}
          </Text>
        </Box>

        {!!button && (
          <Flex justifyContent={'space-between'} mt={5}>
            <Box flex={['0 0 100%', '0 0 auto']}>
              <Button fluid {...button} />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  )
}

export default TestimonialSmall
