import React from 'react'
// Types
import { SectionBaseProps, SectionRoundedHero } from '../../../types/sections'
// Components
import { Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button, Image } from '../../../atoms'

export interface RoundedHeroProps extends SectionBaseProps {
  data: SectionRoundedHero
}

const RoundedHero: React.FC<RoundedHeroProps> = ({ data }) => {
  const { id, headline, subHeadline, whiteText, buttons, asset, backgroundImage, backgroundColor } = data
  return (
    <Box as={'section'} id={id} pt={100} mx={18} mb={[80, 80, 120]}>
      <Box
        position={'relative'}
        mx={'auto'}
        maxWidth={1360}
        borderRadius={'large'}
        bg={backgroundColor?.hex || 'white'}
        overflow={'hidden'}
        py={[8, 10]}
        px={[6, 7]}
      >
        {backgroundImage && (
          <Box position={'absolute'} top={0} left={0} width={'100%'} height={'100%'} zIndex={1}>
            <Image
              draggable={false}
              alt={''}
              image={backgroundImage.gatsbyImageData}
              style={{ overflow: 'hidden', borderRadius: '32px', width: '100%', height: '100%' }}
              imgStyle={{ borderRadius: '3épx' }}
            />
          </Box>
        )}
        <Box position={'relative'} maxWidth={1200} mx={'auto'} zIndex={2}>
          <Row middle={'xs'}>
            <Col xs={12} sm={6}>
              <Box py={[0, 0, 64]}>
                <Heading as={'h3'} color={whiteText ? 'white' : 'dark'}>
                  {headline}
                </Heading>
                {!!subHeadline && (
                  <Box mt={4} pr={[0, 0, 0, 10]}>
                    <Text color={whiteText ? 'white' : 'dark'}>{subHeadline}</Text>
                  </Box>
                )}
                {buttons && buttons.length > 0 && (
                  <Flex mt={[4, 5, 7]} alignItems={'center'} flexWrap={'wrap'}>
                    {buttons.map((b, i) => (
                      <Box
                        key={b.id}
                        flex={['0 0 100%', '0 0 auto']}
                        mr={i + 1 < buttons.length ? 5 : undefined}
                        my={3}
                      >
                        <Button fluid {...b} />
                      </Box>
                    ))}
                  </Flex>
                )}
              </Box>
            </Col>
            <Col xs={12} sm={6}>
              <Box mt={[7, 0]}>
                {asset && <Image draggable={false} alt={asset.alt || headline} image={asset.gatsbyImageData} />}
              </Box>
            </Col>
          </Row>
        </Box>
      </Box>
    </Box>
  )
}

export default RoundedHero
