import React from 'react'
// Types
import { SectionBaseProps, SectionHomeHero } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button, Image } from '../../../atoms'

export interface HomeHeroProps extends SectionBaseProps {
  data: SectionHomeHero
}

const HomeHero: React.FC<HomeHeroProps> = ({ data }) => {
  const { id, headline, subHeadline, buttons, asset, whiteText, backgroundShapes, backgroundColor } = data

  return (
    <Box
      as={'section'}
      id={id}
      position={'relative'}
      backgroundColor={backgroundColor?.hex || 'dark'}
      backgroundImage={[
        'radial-gradient(circle at 0% 70%,rgba(139,1,232,0.25),rgba(255,255,255,0) 50%),radial-gradient(circle at 100% 25%,rgba(139,1,232,0.25),rgba(255,255,255,0) 48%)',
        'radial-gradient(circle at 0% 70%,rgba(139,1,232,0.25),rgba(255,255,255,0) 20%),radial-gradient(circle at 50% 72%,rgba(248,144,144,0.25),rgba(255,255,255,0) 20%),radial-gradient(circle at 100% 70%,rgba(139,1,232,0.25),rgba(255,255,255,0) 18%)',
      ]}
    >
      <Box
        position={'relative'}
        zIndex={2}
        pt={[120, 120, 160, 220]}
        backgroundImage={backgroundShapes ? `url(${backgroundShapes.url})` : undefined}
        backgroundPosition={'bottom center'}
        backgroundSize={'auto'}
        backgroundRepeat={'no-repeat'}
      >
        <Grid>
          <Box mb={[48, 80, 115]}>
            <Row center={'xs'}>
              <Col xs={12} md={11}>
                <Box>
                  <Heading color={whiteText ? 'white' : 'dark'} textAlign={['left', 'center', 'center']}>
                    {headline}
                  </Heading>
                  {!!subHeadline && (
                    <Box mt={2}>
                      <Text color={whiteText ? 'white' : 'dark'} textAlign={['left', 'center', 'center']}>
                        {subHeadline}
                      </Text>
                    </Box>
                  )}
                </Box>
                {buttons && buttons.length > 0 && (
                  <Flex mt={[6, 7, 7]} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
                    {buttons.map((b) => (
                      <Box key={b.id} flex={['0 0 100%', '0 0 auto']} mx={[0, 3]} my={3}>
                        <Button {...b} fluid />
                      </Box>
                    ))}
                  </Flex>
                )}
              </Col>
            </Row>
          </Box>

          {asset && (
            <Box
              mb={[
                `calc(100% / ${asset.sizes?.aspectRatio} / 2 + 64px)`,
                `calc(100% / ${asset.sizes?.aspectRatio} / 2 + 64px)`,
                `calc(100% / ${asset.sizes?.aspectRatio} / 2 + 80px)`,
                `calc(100% / ${asset.sizes?.aspectRatio} / 2 + 110px)`,
              ]}
            >
              <Row center={'xs'}>
                <Col xs={12}>
                  <Box position={'relative'} pb={`calc(100% / ${asset.sizes?.aspectRatio} / 2)`}>
                    <Box position={'absolute'} top={0} left={0} px={[0, 0, 40]}>
                      <Image alt={asset.alt || headline} image={asset.gatsbyImageData} draggable={false} />
                    </Box>
                  </Box>
                </Col>
              </Row>
            </Box>
          )}
        </Grid>
      </Box>
    </Box>
  )
}
export default HomeHero
