import React from 'react'
import theme from '../../../theme'
// Types
import { TestimonialProps } from '../../../types/items'
import { Col, Row } from 'react-styled-flexboxgrid'
import { Box, Button, Flex, Image, Text } from '../../../atoms'

type TestimonialFullProps = TestimonialProps & { backgroundColor?: string | keyof typeof theme.colors }

const TestimonialFull: React.FC<TestimonialFullProps> = ({
  author,
  profilePicture,
  quote,
  role,
  button,
  backgroundColor,
}) => {
  return (
    <Box bg={backgroundColor || 'lightBlueShade'} py={[5, 8, 9, 10]} px={[5, 8, 9, 10]} borderRadius={'large'}>
      <Row>
        <Col xs={12} md={3}>
          <Flex flexDirection={'column'} alignItems={'center'}>
            <Box maxWidth={[120, 120, 240]} mb={5} mt={[4, 0, 0]}>
              <Image
                draggable={false}
                alt={profilePicture.alt || author}
                image={profilePicture.gatsbyImageData}
                style={{ overflow: 'hidden', borderRadius: '9999px' }}
                imgStyle={{ borderRadius: '9999px' }}
              />
            </Box>
            <Box mb={5} display={['block', 'none', 'none']}>
              <Text fontWeight={'semi'} lineHeight={'28px'} textAlign={'center'}>
                {author}
              </Text>
              <Text opacity={0.63} textAlign={'center'}>
                {role}
              </Text>
            </Box>
          </Flex>
        </Col>
        <Col xs={12} md={9}>
          <Box pl={[0, 0, 4]}>
            <Box>
              <Text fontFamily={'hoves'} fontSize={['20px', '20px', '24px']} lineHeight={['32px', '32px', '40px']}>
                {quote}
              </Text>
            </Box>
            <Flex justifyContent={'space-between'} flexWrap={'wrap'} mt={5}>
              <Box mt={3} display={['none', 'block', 'block']}>
                <Text fontWeight={'semi'} lineHeight={'28px'}>
                  {author}
                </Text>
                <Text lineHeight={1.4} opacity={0.63}>
                  {role}
                </Text>
              </Box>
              {!!button && (
                <Box mt={3} flex={['0 0 100%', '0 0 auto']}>
                  <Button fluid {...button} />
                </Box>
              )}
            </Flex>
          </Box>
        </Col>
      </Row>
    </Box>
  )
}

export default TestimonialFull
