import React from 'react'
// Types
import { SectionBaseProps, SectionCareerHero } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Heading, Text, Button, Image } from '../../../atoms'
import { TestimonialFull } from '../../testimonials'

export interface CareerHeroProps extends SectionBaseProps {
  data: SectionCareerHero
}

const CareerHero: React.FC<CareerHeroProps> = ({ data }) => {
  const { id, headline, button, itemsHeadline, items, testimonial, whiteText, backgroundImage, backgroundColor } = data

  const textColor = whiteText ? 'white' : 'dark'

  return (
    <Box as={'section'} id={id}>
      {/* Content */}
      <Box
        position={'relative'}
        bg={backgroundColor ? backgroundColor.hex : 'dark'}
        pt={[140, 160, 180]}
        pb={[6, 7, 8]}
      >
        {backgroundImage && (
          <Box
            display={['none', 'none', 'block']}
            position={'absolute'}
            top={0}
            left={'50%'}
            width={'100%'}
            height={'100%'}
            zIndex={1}
            style={{ transform: 'translate3d(-50%,0,0)' }}
            maxWidth={backgroundImage.gatsbyImageData.width / 2}
          >
            <Image
              draggable={false}
              alt={''}
              image={backgroundImage.gatsbyImageData}
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        )}
        <Box position={'relative'} zIndex={2}>
          <Grid>
            <Row>
              <Col xs={12} md={8} mdOffset={4}>
                <Box mb={[9, 10, 120]}>
                  <Heading as={'h1'} color={textColor}>
                    {headline}
                  </Heading>
                  {button && (
                    <Box mt={6}>
                      <Button size={'lg'} {...button} />
                    </Box>
                  )}
                </Box>
                {/* Values */}
                <Box>
                  {!!itemsHeadline && (
                    <Box mb={[8, 9, 10]}>
                      <Heading as={'h4'} color={textColor}>
                        {itemsHeadline}
                      </Heading>
                    </Box>
                  )}
                  {items && items.length > 0 && (
                    <Box>
                      <Row>
                        {items.map((item) => (
                          <Col key={item.id} xs={12} sm={6}>
                            <Box mb={[9, 10, 100]}>
                              {item.asset && (
                                <Box maxWidth={64} mb={5}>
                                  <Image
                                    draggable={false}
                                    alt={item.asset.alt || item.name}
                                    image={item.asset.gatsbyImageData}
                                  />
                                </Box>
                              )}
                              <Box>
                                <Text fontSize={4} fontWeight={'semi'} color={textColor}>
                                  {item.name}
                                </Text>
                                <Box mt={2}>
                                  <Text fontSize={2} color={textColor} opacity={0.64}>
                                    {item.content}
                                  </Text>
                                </Box>
                              </Box>
                            </Box>
                          </Col>
                        ))}
                      </Row>
                    </Box>
                  )}
                </Box>
              </Col>
            </Row>
          </Grid>
        </Box>
      </Box>
      {/* Testimonial */}
      {testimonial && (
        <Box position={'relative'}>
          <Box
            position={'absolute'}
            width={'100%'}
            height={'50%'}
            top={0}
            left={0}
            zIndex={1}
            bg={backgroundColor ? backgroundColor.hex : 'dark'}
          />
          <Box position={'relative'} zIndex={2}>
            <Grid>
              <Row>
                <Col xs={12}>
                  <TestimonialFull {...testimonial} />
                </Col>
              </Row>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CareerHero
