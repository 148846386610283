import React from 'react'
// Types
import { SectionBase, SectionHeroText } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Box, Flex, Heading, Text, Button } from '../../../atoms'

export interface HeroTextProps extends SectionBase {
  data: SectionHeroText
}

const HeroText: React.FC<HeroTextProps> = ({ data }) => {
  const { id, headline, subHeadline, buttons, whiteText, backgroundColor } = data

  return (
    <Box
      id={id}
      as={'section'}
      pt={[120, 140, 160]}
      pb={[80, 120, 160]}
      backgroundColor={backgroundColor?.hex || 'white'}
      backgroundImage={[
        'radial-gradient(circle at 10% 100%,rgba(139,1,232,0.25),rgba(255,255,255,0) 20%),radial-gradient(circle at 50% 120%,rgba(109,216,252,0.20),rgba(255,255,255,0) 24%),radial-gradient(circle at 90% 100%,rgba(248,144,144,0.20),rgba(255,255,255,0) 20%)',
      ]}
    >
      <Grid>
        <Row middle={'xs'} center={'xs'}>
          <Col xs={12} md={10}>
            <Box>
              <Heading
                as={'h1'}
                fontSize={['32px', '40px', '56px']}
                lineHeight={['40px', '48px', '64px']}
                color={whiteText ? 'white' : 'dark'}
                textAlign={'center'}
              >
                {headline}
              </Heading>
            </Box>
            {!!subHeadline && (
              <Flex mt={[4, 5, 6]} px={[0, 0, 10]} justifyContent={'center'}>
                <Text color={whiteText ? 'white' : 'dark'} textAlign={'center'}>
                  {subHeadline}
                </Text>
              </Flex>
            )}

            {buttons && buttons.length > 0 && (
              <Flex mt={[4, 5, 7]} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
                {buttons.map((b, i) => (
                  <Box
                    key={b.id}
                    flex={['0 0 100%', '0 0 100%', '0 0 auto']}
                    mr={i + 1 < buttons.length ? 5 : undefined}
                    mt={3}
                  >
                    <Button fluid {...b} />
                  </Box>
                ))}
              </Flex>
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default HeroText
