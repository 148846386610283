import React from 'react'
import loadable from '@loadable/component'

//Types
import { HomeHeroProps } from './HomeHero/HomeHero'
import { RoundedHeroProps } from './RoundedHero/RoundedHero'
import { CareerHeroProps } from './CareerHero/CareerHero'
import { FeatureSimpleProps } from './FeatureSimple/FeatureSimple'
import { SectionBaseProps } from '../../types/sections'
import { LogoWallProps } from './LogoWall/LogoWall'
import { TestimonialProps } from './Testimonial/Testimonial'
import { BlogProps } from './Blog/Blog'
import { TitleOnlyProps } from './TitleOnly/TitleOnly'
import { HeroSimpleProps } from './HeroSimple/HeroSimple'
import { FeaturesListProps } from './FeaturesList/FeaturesList'
import { FeaturesStepProps } from './FeaturesStep/FeaturesStep'
import { JoinTeamProps } from './JoinTeam/JoinTeam'
import { TeamProps } from './Team/Team'
import { AboutStoryProps } from './AboutStory/AboutStory'
import { AboutHighlightProps } from './AboutHighlight/AboutHighlight'
import { TabulatedProps } from './Tabulated/Tabulated'
import { AboutHeroProps } from './AboutHero/AboutHero'
import { PricingProps } from './Pricing/Pricing'
import { ResourceFormProps } from './ResourceForm/ResourceForm'
import { StatsListProps } from './StatsList/StatsList'
import { HeroTextProps } from './HeroText/HeroText'
import { VideoProps } from './Video/Video'
import { PodcastProps } from './Podcast/Podcast'

//Components not loadable
import FeatureSimple from './FeatureSimple'
import HomeHero from './HomeHero'
import HeroSimple from './HeroSimple'
import RoundedHero from './RoundedHero'
import AboutHero from './AboutHero'
import CareerHero from './CareerHero'
import HeroText from './HeroText'

interface SectionProps {
  key?: string | number
  sectionApiKey: string
  data: any
}

/*
 We have to write a loadable line for every component to avoid crash during build
 time. loadable have an issue with dynamic import with gatsby.
 NOTE: I keep the loadable component line commented to change quickly between
 the two way to handle this
*/
const SectionComponents: { [key: string]: React.FC<any> } = {
  Default: (props: SectionBaseProps) => {
    const SectionComponent = loadable(() => import('./Default'))
    return <SectionComponent {...props} />
  },
  AboutHero: (props: AboutHeroProps) => {
    // const SectionComponent = loadable(() => import('./AboutHero'))
    return <AboutHero {...props} />
  },
  AboutHighlight: (props: AboutHighlightProps) => {
    const SectionComponent = loadable(() => import('./AboutHighlight'))
    return <SectionComponent {...props} />
  },
  AboutStory: (props: AboutStoryProps) => {
    const SectionComponent = loadable(() => import('./AboutStory'))
    return <SectionComponent {...props} />
  },
  CareerHero: (props: CareerHeroProps) => {
    // const SectionComponent = loadable(() => import('./CareerHero'))
    return <CareerHero {...props} />
  },
  HeroSimple: (props: HeroSimpleProps) => {
    // const SectionComponent = loadable(() => import('./HeroSimple'))
    return <HeroSimple {...props} />
  },
  HomeHero: (props: HomeHeroProps) => {
    // const SectionComponent = loadable(() => import('./HomeHero'))
    return <HomeHero {...props} />
  },
  RoundedHero: (props: RoundedHeroProps) => {
    // const SectionComponent = loadable(() => import('./RoundedHero'))
    return <RoundedHero {...props} />
  },
  Blog: (props: BlogProps) => {
    const SectionComponent = loadable(() => import('./Blog'))
    return <SectionComponent {...props} />
  },
  FeatureSimple: (props: FeatureSimpleProps) => {
    // const SectionComponent = loadable(() => import('./FeatureSimple'))
    return <FeatureSimple {...props} />
  },
  FeaturesList: (props: FeaturesListProps) => {
    const SectionComponent = loadable(() => import('./FeaturesList'))
    return <SectionComponent {...props} />
  },
  FeaturesStep: (props: FeaturesStepProps) => {
    const SectionComponent = loadable(() => import('./FeaturesStep'))
    return <SectionComponent {...props} />
  },
  JoinTeam: (props: JoinTeamProps) => {
    const SectionComponent = loadable(() => import('./JoinTeam'))
    return <SectionComponent {...props} />
  },
  LogoWall: (props: LogoWallProps) => {
    const SectionComponent = loadable(() => import('./LogoWall'))
    return <SectionComponent {...props} />
  },
  Team: (props: TeamProps) => {
    const SectionComponent = loadable(() => import('./Team'))
    return <SectionComponent {...props} />
  },
  Testimonial: (props: TestimonialProps) => {
    const SectionComponent = loadable(() => import('./Testimonial'))
    return <SectionComponent {...props} />
  },
  TitleOnly: (props: TitleOnlyProps) => {
    const SectionComponent = loadable(() => import('./TitleOnly'))
    return <SectionComponent {...props} />
  },
  Tabulated: (props: TabulatedProps) => {
    const SectionComponent = loadable(() => import('./Tabulated'))
    return <SectionComponent {...props} />
  },
  Pricing: (props: PricingProps) => {
    const SectionComponent = loadable(() => import('./Pricing'))
    return <SectionComponent {...props} />
  },
  ResourceForm: (props: ResourceFormProps) => {
    const SectionComponent = loadable(() => import('./ResourceForm'))
    return <SectionComponent {...props} />
  },
  StatsList: (props: StatsListProps) => {
    const SectionComponent = loadable(() => import('./StatsList'))
    return <SectionComponent {...props} />
  },
  HeroText: (props: HeroTextProps) => {
    // const SectionComponent = loadable(() => import('./HeroText'))
    return <HeroText {...props} />
  },
  Video: (props: VideoProps) => {
    const SectionComponent = loadable(() => import('./Video'))
    return <SectionComponent {...props} />
  },
  Podcast: (props: PodcastProps) => {
    const SectionComponent = loadable(() => import('./Podcast'))
    return <SectionComponent {...props} />
  },
}

const Section: React.FC<SectionProps> = (props) => {
  //Here we convert snake case api key eg: s_feature_simple
  //to SFeatureSimple then we remove the S to match with the component name
  const sectionComponentName = snake2Pascal(props.sectionApiKey).substring(1)
  const SectionComponent = SectionComponents[sectionComponentName] || SectionComponents['Default']
  return <SectionComponent {...props} />
}

export default Section

//Utilities
function snake2Pascal(str: string) {
  return str
    .split('_')
    .map((s) => {
      return s.slice(0, 1).toUpperCase() + s.slice(1, s.length)
    })
    .join('')
}
