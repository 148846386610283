import React from 'react'
// Types
import { SectionBaseProps, SectionHeroSimple } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Heading, Text, Button, Image } from '../../../atoms'

export interface HeroSimpleProps extends SectionBaseProps {
  data: SectionHeroSimple
}

const HeroSimple: React.FC<HeroSimpleProps> = ({ data }) => {
  const { id, headline, subHeadline, whiteText, buttons, backgroundImage, backgroundImageMobile, backgroundColor } =
    data

  return (
    <Flex
      as={'section'}
      id={id}
      alignItems={'center'}
      justifyContent={'center'}
      pt={[120, 140, 180]}
      pb={backgroundImageMobile ? [0, 0, 160] : [80, 100, 160]}
      minHeight={['auto', 'auto', 800]}
      position={'relative'}
      mx={'auto'}
      bg={backgroundColor?.hex || 'white'}
    >
      {backgroundImage && (
        <Box
          display={['none', 'none', 'block']}
          position={'absolute'}
          top={0}
          left={'50%'}
          width={'100%'}
          height={'100%'}
          zIndex={1}
          style={{ transform: 'translate3d(-50%,0,0)' }}
          maxWidth={backgroundImage.gatsbyImageData.width}
        >
          <Image
            draggable={false}
            alt={''}
            image={backgroundImage.gatsbyImageData}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
      )}
      <Box flex={'0 0 100%'} position={'relative'} zIndex={2}>
        <Grid>
          <Row middle={'xs'}>
            <Col xs={12} md={8} lg={7}>
              <Box pr={[0, 0, 0, 8]}>
                <Heading
                  fontSize={['32px', '40px', '56px']}
                  lineHeight={['40px', '48px', '64px']}
                  color={whiteText ? 'white' : 'dark'}
                >
                  {headline}
                </Heading>
              </Box>
              {!!subHeadline && (
                <Box mt={4} pr={[0, 0, 0, 10]}>
                  <Text color={whiteText ? 'white' : 'dark'}>{subHeadline}</Text>
                </Box>
              )}
              {buttons && buttons.length > 0 && (
                <Flex mt={[4, 5, 7]} alignItems={'center'} flexWrap={'wrap'}>
                  {buttons.map((b, i) => (
                    <Box key={b.id} flex={['0 0 100%', '0 0 auto']} mr={i + 1 < buttons.length ? 5 : undefined} my={3}>
                      <Button fluid {...b} />
                    </Box>
                  ))}
                </Flex>
              )}
            </Col>
          </Row>
        </Grid>

        {backgroundImageMobile && (
          <Box display={['block', 'block', 'none']}>
            <Image
              alt={backgroundImageMobile.alt || headline}
              draggable={false}
              image={backgroundImageMobile.gatsbyImageData}
            />
          </Box>
        )}
      </Box>
    </Flex>
  )
}

export default HeroSimple
