import React from 'react'
import { Box, BoxProps } from '../../../atoms'
import styled, { css } from 'styled-components'

export const BoxWithDot: React.FC<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }> = styled(
  Box
)<BoxProps & { as?: React.ElementType; dotColor: string; ordered: boolean }>`
  ${({ ordered }) =>
    !ordered &&
    css`
      &:before {
        content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAzNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgb3BhY2l0eT0iMC40OCIgeT0iMTYiIHdpZHRoPSIxNiIgaGVpZ2h0PSIzNiIgcng9IjgiIHRyYW5zZm9ybT0icm90YXRlKC05MCAwIDE2KSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyKSIvPgo8Y2lyY2xlIGN4PSIyOCIgY3k9IjgiIHI9IjgiIGZpbGw9IiNGODkwOTAiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9IjgiIHkxPSIxNiIgeDI9IjgiIHkyPSI1MiIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjRjg5MDkwIi8+CjxzdG9wIG9mZnNldD0iMC42ODIyOTIiIHN0b3AtY29sb3I9IiNGODkwOTAiIHN0b3Atb3BhY2l0eT0iMCIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=');
        width: 36px;
        height: 16px;
        border-radius: 100%;
        position: absolute;
        top: 4px;
        left: -24px;
      }
    `}
`
